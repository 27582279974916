import React,{useState,useEffect} from 'react';
import Menu from '../layout/Menu';
import {isAuthenticated} from '../user/userController';
import {createEvent} from '../layout/newsController';

const AdminEvent = () => {

    const [event,setEvent] = useState({
        content:'',
        photo:'',
        formData:''
    })

    const {formData} = event;

    const init = () => {
        setEvent({...event,formData:new FormData()})
    }
    
    const {token} = isAuthenticated();

    useEffect(() => {
        init()
    },[])

    const handleChangeE = name => e => {
        const value = name === 'photo' ? e.target.files[0] : e.target.value;
        formData.set(name,value)
        setEvent({...event,[name]: value})
    }


    const clickSubmitEvent = (e) => {
        e.preventDefault();
        setEvent({...event,error:'',loading:true});
        createEvent(token,formData)
        .then(data=>{
            console.log(data);
            console.log(event);
            if (data.error) {
                setEvent({...event,error:data.error})
            }else{
                setEvent({
                    ...event,
                    content:'',
                    photo:'',
                });
            }
        }).then(()=>{
            window.location.reload()
        });
    };

    return (
        <div>
            <Menu/>
            <h1>Evento</h1>
            <form onSubmit={clickSubmitEvent}>
                <br/>
                <label htmlFor="photo"  className="button bg-color-orange color-white">Foto</label>
                <br/>
                <input type="file" onChange={handleChangeE('photo')}  accept="image/*" className="inputfile" name="photo" id="photo"/>
                <br/>
                <textarea className="input txt-area" onChange={handleChangeE('content')}  name="content" placeholder="contenuto"></textarea>
                <br/>
                <button className="button bg-color-orange">Send</button>
            </form>
        </div>
    )
}

export default AdminEvent;
    