import React,{useState,useEffect} from 'react';
import Menu from '../layout/Menu';
import {isAuthenticated} from '../user/userController';
import {createPost} from '../layout/newsController';

const AdminDashboard = () => {

    const [post,setPost] = useState({
        title:'',
        content:'',
        photo:'',
        formData:''
    })

    const {formData} = post;

    const init = () => {
        setPost({...post,formData:new FormData()})
    }

    const {token} = isAuthenticated();

    useEffect(() => {
        init()
    },[])

    const handleChange = name => event => {
        const value = name === 'photo' ? event.target.files[0] : event.target.value;
        formData.set(name,value)
        setPost({...post,[name]: value})
    }

    const clickSubmit = (event) => {
        event.preventDefault();
        setPost({...post,error:'',loading:true});
        createPost(token,formData)
        .then(data=>{
            if (data.error) {
                setPost({...post,error:data.error})
            }else{
                setPost({
                    ...post,
                    title:'',
                    content:'',
                    photo:'',
                });
            }
        }).then(()=>{
            window.location.reload()
        })
    };

    
    return (
        <div>
            <Menu/>
            <h1>Post</h1>
            <form onSubmit={clickSubmit}>
                <br/>
                <label htmlFor="photo"  className="button bg-color-orange color-white">Foto</label>
                <br/>
                <input type="file" onChange={handleChange('photo')}  accept="image/*" className="inputfile" name="photo" id="photo"/>
                <br/>
                <input type="text" onChange={handleChange('title')}  className="input" name="title" id="" placeholder="titolo"/>
                <br/>
                <textarea className="input txt-area" onChange={handleChange('content')}  name="content" id="" placeholder="contenuto"></textarea>
                <br/>
                <button className="button bg-color-orange">Send</button>
            </form>
            
        </div>
    )
}

export default AdminDashboard;
