export const getPosts = (page) => {
    return fetch('https://api.gd-latina.com/feed/posts?page='+page,
    {
        method: "GET",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json"
        }
    }).then((res)=>{return res.json()})
}

export const getPost = (postId) => {
    return fetch('https://api.gd-latina.com/feed/post/'+postId,
    {
        method: "GET",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json"
        }
    }).then((res)=>{return res.json()})
}

export const createPost = (token,data) => {
    return fetch('https://api.gd-latina.com/feed/post',
    {
        method: "POST",
        headers: {
            Accept:"application/json",
            Authorization:("Bearer "+token)
        },
        body: data
    })
    .then((res)=>{return res.json()})
    .catch(err=>console.log(err))
}

export const updatePost = (token,data,postId) => {
    return fetch('https://api.gd-latina.com/feed/post/'+postId,
    {
        method: "PUT",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:("Bearer "+token)
        },
        body: JSON.stringify(data)
    })
    .then((res)=>{return res.json()})
    .catch(err=>console.log(err))
}

export const deletePost = (token,postId) => {
    return fetch('https://api.gd-latina.com/feed/post/'+postId,
    {
        method: "DELETE",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:("Bearer "+token)
        }
    })
    .then((res)=>{return res.json()})
    .catch(err=>console.log(err))
}

export const getEvents = (page) => {
    return fetch('https://api.gd-latina.com/feed/events?page='+page,
    {
        method: "GET",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json"
        }
    }).then((res)=>{return res.json()})
}

export const createEvent= (token,data) => {
    return fetch('https://api.gd-latina.com/feed/event',
    {
        method: "POST",
        headers: {
            Accept:"application/json",
            Authorization:("Bearer "+token)
        },
        body: data
    })
    .then((res)=>{return res.json()})
    .catch(err=>console.log(err))
}

export const deleteEvent = (token,postId) => {
    return fetch('https://api.gd-latina.com/feed/event/'+postId,
    {
        method: "DELETE",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json",
            Authorization:("Bearer "+token)
        }
    })
    .then((res)=>{return res.json()})
    .catch(err=>console.log(err))
}
