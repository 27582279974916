import React from 'react';
import Menu from './Menu';
import "../style/Contacts.css";
import majocchi_logo from '../images/majocchi2.jpg';
import Footer from './Footer';

const Contacts = () => {
    return (
        <div>
            <Menu/>
            <div className=" align-center ">
                <div className="block">
                    <div className="align-center">
                        <div className="block">
                            <div className="flex contact-card">
                                <div className="center">
                                    <img src={majocchi_logo} className="rounded img-people" alt=""/>
                                </div>
                                <div className="block">
                                    <div>
                                        <h3 className="color-gd">Leonardo Majocchi</h3>
                                    </div>
                                    <div>
                                        <h5>Numero di Telefono: 347-244-7389</h5>
                                    </div>
                                    <div>
                                        <h5>Email: leomaj@hotmail.it</h5>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                    
                        <br/>

                        <div>
                            <h1 className="color-gd" >Come iscriversi?</h1>
                            <div className="align-center">
                                <div className="post-paragraph">
                                    <p>Ti interessa la politica e cerchi un posto in cui discutere ed impegnarti per una realtà più democratica? Se la risposta è affermativa 
                                        o semplicemente vuoi saperne di più
                                         contattaci, e saremo felici di risponderti
                                         Link ai profili social e recapiti di alcuni noi sono sotto la voce "Contatti". Ricorda che puoi trovarci ogni sabato pomeriggio, dalle 17.00, nella sede del Partito Democratico di Latina, in via Carlo Romagnoli n.31. Ti aspettiamo!</p>
                                </div>  
                            </div>
                        </div>
                    </div>
                </div>       
                <br/>
                <div>
                    <h1 className="color-gd" >Dove Siamo?</h1>
                    <div>
                    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.556998692044!2d12.891189715428418!3d41.470522679256646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13250b7f374dfd05%3A0x12470d8b6a08bd5d!2sVia%20Carlo%20Romagnoli%2C%2031%2C%2004100%20Latina%20LT!5e0!3m2!1sit!2sit!4v1572280627020!5m2!1sit!2sit" width="100%" height="450" frameBorder="0" title="prova2" ></iframe>                    </div>
                </div> 
            <Footer/>    
        </div>
    )
}

export default Contacts
