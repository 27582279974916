import React from 'react';
import Menu from './Menu';
import {Link} from 'react-router-dom';
import pub_gd from '../images/pub_gd.jpeg';
import foto_gruppo_gd from '../images/foto_gruppo_gd.jpeg';
import assemblea_aperta from '../images/assemblea_aperta.jpg';
import arcigay from '../images/arcigay.jpeg';
import Footer from './Footer';

const About = () => {
    return (
        <div>
            <Menu/>
            <h1 className="color-gd">Questo è quello che facciamo</h1>
            <br/>
            <div className="align-center">
                <div className="block">
                    <div className="align-center">
                        <div className="block">
                                <div className="align-center">
                                    <div className="center text-align-center card">
                                        <img src={pub_gd} className="image_st_post" alt=""/>
                                    </div>
                                </div>
                                <p className="color-gd about-txt"> 
                                    Aperitivo sociale:
                                    <br/>
                                    uno dei tanti momenti d’aggregazione e discussione in un pub, intorno a qualche buona birra.
                                    Con tanta passione, la politica è anche questo.
                                </p>
                                <div className="align-center">
                                    <div className="center text-align-center card">
                                        <img src={foto_gruppo_gd} className="image_st_post" alt=""/>
                                    </div>
                                </div>
                                <p className="color-gd about-txt"> 
                                    Discussione e cura degli spazi.
                                    <br/>
                                    Nell’ambito degli allora consueti momenti di analisi del sabato pomeriggio, la pulizia di spazi, in questo caso Parco San Marco, ove al discussione aveva luogo.
                                </p>
                                <div className="align-center">
                                    <div className="center text-align-center card">
                                        <img src={arcigay} className="image_st_post" alt=""/>
                                    </div>
                                </div>
                                <p className="color-gd about-txt"> 
                                    Incontri tematici e dibattiti: 
                                    <br/>
                                    uno dei tanti incontri tematici organizzati per il ciclo di incontri GDelvenerdì.
                                    In foto incontro con SeiComeSei - ArciGay Latina.
                                </p>
                                <div className="align-center">
                                    <div className="center text-align-center card">
                                        <img src={assemblea_aperta} className="image_st_post" alt=""/>
                                    </div>
                                </div>
                                <p className="color-gd about-txt"> 
                                    Assemblee aperte.
                                    <br/>
                                    Un’assemblea in avvicinamento alle elezioni europee con la presenza della Rete degli Studenti Medi, una delle tante realtà con le quali collaboriamo
                                </p>
                            <div className="align-center">
                                <Link to="/more">
                                    <button className="button bg-color-gd txt-s">Scopri di più</button>
                                </Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <br/>
            <Footer/>
        </div>
    )
}

export default About
