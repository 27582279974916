import React,{useState,useEffect,Fragment} from 'react';
import Menu from './Menu';
import{getEvents,deleteEvent} from './newsController';
import ShowImage from './ShowImageEvent';
import {isAuthenticated} from '../user/userController';

const More = (props) => {
    const [events,setEvents] = useState([])
    const [page,setPage] = useState(1)

    const {token} = isAuthenticated();

    useEffect(() => {
        getEvents(page.toString()).then(res => {
            console.log(page)
            setEvents(res.events);
        })
    }, [page])

    return (
        <div>
            <Menu/>
            <div className="center">
                <div className="block">
                    {events.map((p,i)=>{
                            return (
                                <Fragment key={i}>
                                    <div className="align-center">
                                        <div className="center text-align-center card">
                                            <ShowImage item = {p} itype="image_st_post"/>
                                        </div>
                                    </div>
                                    <p className="color-gd about-txt"> 
                                        {p.content}
                                    </p>
                                    <div key={i} className="block">
                                        {
                                            isAuthenticated() && ( 
                                                <Fragment>
                                                    <h1>Admin tools</h1>
                                                    <div className="center">
                                                        <div className="block">
                                                            <button className="button bg-color-red" onClick={()=>{deleteEvent(token,p._id).then(()=>props.history.push("/dashboard"))}}>Delete</button>
                                                        </div>
                                                    </div>
                                                </Fragment>
                                                )
                                        }
                                    </div>

                                </Fragment>)
                        })}
                        <div className="space-between">
                            <button className="button bg-color-orange" onClick={()=>{
                                if (page>1) {
                                    setPage(page-1)
                                }else{
                                    console.log("first page")
                                }
                                
                            }}>{"-"}</button>
                            <button className="button bg-color-orange" onClick={()=>{
                                setPage(page+1)
                                
                            }}>{"+"}</button>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default More
