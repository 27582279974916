import React from 'react';
import { HashRouter as Router,Switch,Route} from 'react-router-dom';
import Home from './layout/Home';
import About from './layout/About';
import Login from './user/Login';
import News from './layout/News';
import Post from './layout/Post';
import Contacts from './layout/Contacts';
import More from './layout/More';
import AdminDashboard from './user/AdminDashboard';
import AdminRoute from './user/AdminRoute';
import AdminUpdate from './user/AdminUpdate';
import AdminEvent from './user/AdminEvent';

const Routes = () => {
    return (
        <Router>
            <Switch>
                <Route path="/" exact component={Home}/>
                <Route path="/about" exact component={About}/>
                <Route path="/login" exact component={Login}/>
                <Route path="/news" exact component={News}/>
                <Route path="/contacts" exact component={Contacts}/>
                <Route path="/more" exact component={More}/>
                <Route path="/post/:postId" exact component={Post}/>
                <AdminRoute 
                path="/dashboard" 
                exact component={AdminDashboard}/>
                <AdminRoute 
                path="/admin/update/:postId" 
                exact component={AdminUpdate}/>
                <AdminRoute 
                path="/admin/event" 
                exact component={AdminEvent}/>
            </Switch>
        </Router>
    )
}

export default Routes