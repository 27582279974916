import React,{useState,Fragment} from 'react';
import {Link,withRouter} from 'react-router-dom';
import "../style/Menu.css";
import logo from '../images/Giovani_Democratici.svg';
import {isAuthenticated} from '../user/userController';
const isActive = (history,path) => {
    if(history.location.pathname === path){
        return "color-dark"
    }else{
        return "color-gd"
    }
}

const signout = () => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem('jwt')
        window.location.reload(); 
    }
}

const Menu = ({history}) => {

    const [block,setBlock] = useState('')

    const {userId} = isAuthenticated();

    return (
        <div className="align-center">
            <div className="block w-100">
                <div className="burgerbtn bg-color-white">
                    <img src={logo} alt="" className="menu_logo"/>
                    <button className="burgbutton" onClick={()=>{
                        if (block === "") {
                            setBlock("block")
                        }else{
                            setBlock("")
                        }
                    }}>
                        <div className="line bg-color-gd"></div>
                        <div className="small-line bg-color-gd"></div>
                        <div className="line bg-color-gd"></div>
                    </button>
                </div>
                <div className="nav-container bg-color-white ">
                    <div className="container_logo">
                            <img src={logo} alt="" className="first menu_logo pd-sm"/>
                    </div>
                    <div className="center">
                        <ul className={"nav-list " + block}>
                            <li className="nav-list-item">
                                <Link className={"link color-hover txt-s "+isActive(history,'/')} to="/">Home</Link>
                            </li>
                            <li className="nav-list-item">
                                <Link className={"link color-hover txt-s "+isActive(history,'/about')} to="/about">Cosa facciamo</Link>
                            </li>
                            <li className="nav-list-item">
                                <Link className={"link color-hover txt-s "+isActive(history,'/news')} to="/news">News</Link>
                            </li>
                            <li className="nav-list-item">
                                <Link className={"link color-hover txt-s "+isActive(history,'/contacts')} to="/contacts">Contatti</Link>
                            </li>
                            {userId &&  (<Fragment>
                                <li className="nav-list-item first">
                                    <Link className={"link color-hover txt-s "+isActive(history,'/dashboard')} to="/dashboard">Dashboard</Link>
                                </li>
                                <li className="nav-list-item">
                                    <Link className={"link color-hover txt-s "+isActive(history,'/admin/event')} to="/admin/event">Eventi</Link>
                                </li>
                                <li className="nav-list-item last">
                                    <span className="link color-hover txt-s color-white" to="" onClick={()=>signout(()=>{
                                    history.push('/');
                                    })}>
                                        <button className="button color-white bg-color-gd txt-s">Logout</button> 
                                    </span>
                                </li>
                                </Fragment>) }
                            {!userId && (<li className="nav-list-item first last">
                                <Link className={"link "+isActive(history,'/login')} to="/login">
                                    <button className="button color-white bg-color-gd txt-s">Login</button> 
                                    </Link>
                            </li>)}                        
                        </ul>
                    </div>
                </div>
            </div>
        </div>
            
    )
}

export default withRouter(Menu)