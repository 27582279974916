import React,{useState,useEffect} from 'react';
import Menu from '../layout/Menu';
import {getPost,updatePost} from '../layout/newsController';
import {isAuthenticated} from '../user/userController';

const AdminUpdate = (props) => {
    const [post,setPost] = useState({
        title:'',
        content:''
    });
    const [postId,setPostId] = useState('');

    useEffect(() => {
        let postId = props.match.params.postId;
        setPostId(postId)
        getPost(postId).then(res => {
            console.log(res.post)
            setPost(res.post);
        })
    },[props.match.params.postId])

    const {token} = isAuthenticated();

    
    const clickSubmit = (event) => {
        event.preventDefault();
        setPost({...post});

        updatePost(token,post,postId)
        .then(data=>{
            if (data.error) {
                setPost({...post,error:data.error})
            }else{
                setPost({
                    ...post,
                    title:'',
                    content:''
                });
            }
        });
    };

    const handleChange = name => event => {
        setPost({...post,[name]: event.target.value})
    }

    return (
        <div>
            <Menu/>
            <form onSubmit={clickSubmit}>
                <input type="text" onChange={handleChange('title')} className="input" value={post.title} name="title" id=""/>
                <br/>
                <textarea className="input txt-area" onChange={handleChange('content')} value={post.content} name="content" id="" ></textarea>
                <br/>
                <button className="button bg-color-orange">Send</button>
            </form>
        </div>
    )
}

export default AdminUpdate
