import '../style/Home.css';
import React,{useState,useEffect,Fragment} from 'react';
import {Link}from 'react-router-dom';
import Menu from './Menu';
import{getPosts} from './newsController';
import ShowImage from './ShowImage';
import Footer from './Footer';
import mini_logo from '../images/Giovani_Democratici.svg';
import home_logo from '../images/gd-latina.jpg';
import insta_logo from '../images/instagram.png';
import facebook_logo from '../images/facebook.png';
import twitter_logo from '../images/twitter.png';
import youtube_logo from '../images/youtube_logo.jpg';

const Home = () => {

    const [posts,setPosts] = useState([])

    useEffect(() => {
        getPosts(1).then(res => {
            setPosts(res.posts);
        })
    }, [])

    return (
        <div>
            <Menu/>
            <div className="align-center">
                <div className="block">
                    <br/>
                    <div className="first-div-home">
                        <div className="side-div">
                            <div className="block">
                                <div className="home-icon">
                                    <a href="https://www.instagram.com/gd_latinacitta/">
                                        <img src={insta_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                                <div className="home-icon">
                                    <a href="https://it-it.facebook.com/GdLatinacitta/">
                                        <img src={facebook_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                                <div className="home-icon">
                                    <a href="https://twitter.com/GiovaniLatina">
                                        <img src={twitter_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                                <div className="home-icon">
                                    <a href="https://www.youtube.com/channel/UCHU7di0wKS740PqWv3sNPmg">
                                        <img src={youtube_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="align-center">
                            <div className="fluid">
                                <div className="bg-color-orange home-div align-center align-center-h br-gd">
                                    <div className="m-sm">
                                        <h1 className="color-white">E' BELLO ESSERE PARTE DI QUALCOSA</h1>
                                    </div>
                                </div>
                                <div className="align-center home-div align-center-h br-gd">
                                    <img src={home_logo} className="max-img-fluid" alt=""/>
                                </div>
                            </div>
                        </div>
                    </div>
                    <br/>
                    <div className="social-bar">
                        <div className="block">
                            <div>
                                <h1 className="color-gd">I nostri social</h1>
                            </div>
                            <div className="center">
                                <div className="home-icon">
                                    <a href="https://www.instagram.com/gd_latinacitta/">
                                        <img src={insta_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                                <div className="home-icon">
                                    <a href="https://it-it.facebook.com/GdLatinacitta/">
                                        <img src={facebook_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                                <div className="home-icon">
                                    <a href="https://twitter.com/GiovaniLatina">
                                        <img src={twitter_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                                <div className="home-icon">
                                    <a href="https://www.youtube.com/channel/UCHU7di0wKS740PqWv3sNPmg">
                                        <img src={youtube_logo} className="social-icon-home" alt=""/>
                                    </a>
                                </div>
                            </div>

                        </div>
                    </div>
                    <br/>
                    <div >
                        <h1 className="color-gd" >Ultime News</h1>
                    </div>
                    <br/>
                    <br/>
                    <div className="align-center">
                        <div className="fluid">
                            {posts.map((p,i)=>{
                                    return (
                                        <Fragment key={i}>
                                            <div key={i} className="block pd-1v">
                                                <Link className="link color-black" to={"/post/"+p._id}>
                                                    <div className="center text-align-center card-sm">
                                                            <ShowImage item = {p} itype="image_st_post-sm"/>
                                                    </div>
                                                </Link>
                                            </div>
                                        </Fragment>)
                                })}
                        </div>
                    </div>
                    <div className="block">
                        <div className="alone">
                            <Link to="/contacts" >
                                <button className="button bg-color-gd txt-s">Contattaci</button>
                            </Link>
                        </div>
                        <div>
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2989.556998692044!2d12.891189715428418!3d41.470522679256646!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x13250b7f374dfd05%3A0x12470d8b6a08bd5d!2sVia%20Carlo%20Romagnoli%2C%2031%2C%2004100%20Latina%20LT!5e0!3m2!1sit!2sit!4v1572280627020!5m2!1sit!2sit" width="100%" height="450" frameBorder="0" title="prova" ></iframe> 
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default Home
