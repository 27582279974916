import React from 'react';
import '../style/Footer.css';
import logo from '../images/Giovani_Democratici.svg';

const Footer = () => {
    return (
        <div className="bg-color-dark-black align-center">
            <div className="block foot">
                <div className="align-center">
                    <div className="flex">
                        <div className="block f-block text-align-left">
                            <h2 className="color-dark-gray">Contacts</h2>
                            <p className="color-dark-gray">Email: <span className="color-white">leomaj@hotmail.it</span></p>
                        </div>
                        <div className="block f-block text-align-left">
                            <h2 className="color-dark-gray">Posizione</h2>
                            <p><span className="color-white">Via Carlo Romagnoli 31,LT</span></p>
                        </div>
                    </div>
                </div>
                
                <hr className="color-gd"/>
                <div className="space-between">
                    <div>
                        <img src={logo} className="f-logo" alt=""/>
                    </div>
                    <div>
                        <p className="color-white">All rights reserved GD latina© 2019</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer
