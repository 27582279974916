import React,{useState,useEffect,Fragment} from 'react';
import Menu from './Menu';
import ShowImage from './ShowImage';
import {getPost,deletePost} from './newsController';
import {Link} from 'react-router-dom'
import {isAuthenticated} from '../user/userController';

const Post = (props) => {

    const [post,setPost] = useState({});
    const [postId,setPostId] = useState('');

    useEffect(() => {
        let postId = props.match.params.postId;
        setPostId(postId);
        getPost(postId).then(res => {
            setPost(res.post);
        })
    },[props.match.params.postId])

    const {token} = isAuthenticated();

    return (
        <div>
            <Menu/>
            <br/>
            <div className="align-center">
                <div className="block">
                    <div className=" center ">
                        <div className="center card">
                            <ShowImage item = {post} itype="image_st_post"/>
                        </div>
                    </div>
                    <br/>
                    <h1>{post.title}</h1>
                    <div className="align-center">
                        <div className="post-paragraph">
                            <p className="text-align-center">{post.content}</p>
                        </div>  
                    </div>
                </div>
            </div>
            {
                isAuthenticated() && ( 
                    <Fragment>
                        <h1>Admin tools</h1>
                        <div className="center">
                            <div className="block">
                                <Link to={"/admin/update/"+post._id}> <button className="button bg-color-green">Update</button></Link>
                                <button className="button bg-color-red" onClick={()=>{deletePost(token,postId).then(()=>props.history.push("/news"))}}>Delete</button>
                            </div>
                        </div>
                    </Fragment>
                    )
            }
        </div>
    )
}

export default Post
