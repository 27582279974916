import React,{useState} from 'react';
import {sendLogin,isAuthenticated} from './userController';
import Menu from '../layout/Menu';

const Login = (props) => {

    const [userData,setUserData] = useState({
        email:'',
        password:''
    })

    const {userId} = isAuthenticated();

    const handleChange = name => event => {
        setUserData({...userData, [name]: event.target.value});
    } 

    const submitted = async (e) => {
         e.preventDefault()
         sendLogin(userData).then(()=>window.location.reload())
         
    }
    return (
        <div>
            <Menu/>
            {userId ? (
            <div className="center">
                <div className="center bg-color-green w-50 pd-4">
                    <h1 className="color-white">You are logged in </h1>
                </div>
            </div>
            
            ) : (
                <form onSubmit={submitted}>
                    <h1>Login Admin</h1>
                    <input 
                    name="email"
                    onChange={handleChange('email')}
                    type="text" 
                    className="input" 
                    value={userData.email}
                    placeholder="email"/>
                    <br/>
                    <input 
                    name="password"
                    onChange={handleChange('password')}
                    type="password" 
                    className="input" 
                    value={userData.password}
                    placeholder="password"/>
                    <br/>
                    <div className="center">
                        <button type="submit" className="button bg-color-orange">Submit</button>
                    </div>
                </form>
            )}    
        </div>
    )
}

export default Login
