export const sendLogin = (data) => {
    return fetch('https://api.gd-latina.com/auth/login',
    {
        method: "POST",
        headers: {
            Accept:"application/json",
            "Content-Type":"application/json"
        },
        body: JSON.stringify(data)
    }).then(res => {
        return res.json()
    }).then(res => {
        
        if (res.message) {
            console.log(res.message)
        }
        else  {
            if (typeof window !== 'undefined') {
                localStorage.setItem('jwt', JSON.stringify(res))
            }
        }
        
    })
    .catch(err => console.log(err));
}

export const isAuthenticated = () => {
    if (typeof window == 'undefined') {
        return false
    }
    if (localStorage.getItem('jwt')) {
        return JSON.parse(localStorage.getItem('jwt'))
    }else{
        return false;
    }
}