import React,{useState,useEffect,Fragment} from 'react';
import {Link}from 'react-router-dom';
import Menu from './Menu';
import{getPosts} from './newsController';
import ShowImage from './ShowImage';
import mini_logo from '../images/Giovani_Democratici.svg';
import moment from 'moment';

const News = () => {

    const [posts,setPosts] = useState([])
    const [page,setPage] = useState(1)

    useEffect(() => {
        getPosts(page.toString()).then(res => {
            console.log(page)
            setPosts(res.posts);
        })
    }, [page])

    return (
        <div>
            <Menu/>
            <div className="center">
                <div className="block">
                    {posts.map((p,i)=>{
                            return (
                                <Fragment key={i}>
                                    <div key={i} className="block mb-2vh">
                                        <Link className="link color-black" to={"/post/"+p._id}><h1 >{p.title}</h1></Link>
                                        <p>{moment(p.createdAt).fromNow()}</p> 
                                        <br/>
                                        <Link className="link color-black" to={"/post/"+p._id}>
                                            <div className="align-center">
                                                <div className="center text-align-center card">
                                                        <ShowImage item = {p} itype="image_st_post"/>
                                                </div>
                                            </div>
                                        </Link>
                                    </div>
                                </Fragment>)
                        })}
                        <div className="space-between">
                            <button className="button bg-color-orange" onClick={()=>{
                                if (page>1) {
                                    setPage(page-1)
                                }else{
                                    console.log("first page")
                                }
                                
                            }}>{"-"}</button>
                            <button className="button bg-color-orange" onClick={()=>{
                                setPage(page+1)
                                
                            }}>{"+"}</button>
                        </div>
                </div>
            </div>
        </div>
    )
}

export default News
